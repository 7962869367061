import React, { useEffect } from "react";


const Form = () => {
  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;

    s.src = "https://yastatic.net/s3/frontend/forms/_/embed.js";

    const h = document.getElementById("yandexform");
    h.parentNode.insertBefore(s, h);
  }, []);
  const form = false;
  useEffect(() => {
    const iframe = document.createElement("iframe");

    iframe.height = "100%";
    iframe.width = "auto";
    iframe.src = "https://forms.yandex.ru/u/62a86673cea6b7c891e57f72/?iframe=1";

    const diviframe = document.getElementById("iframeform");
    diviframe.parentNode.insertBefore(iframe, diviframe);

  }, []);

  return (
 <>

      <div className="form">
      <form className="decor">
        <div className="form-inner">
          <h3>Написать нам</h3>
          <div className="form__ya">
            <div id="yandexform"></div>
            <div id="iframeform"></div>
          </div>
        </div>
      </form>
    </div>
    </>

  );

};

export default Form;
