import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import Form from "../components/form";
const Service = () => {
  return (
    <Layout>
      <div className="container">
        <div className="mt-60">
          <h1 className="text-center">СЕРВИСНЫЙ ЦЕНТР</h1>
          <div className="service mt-30">
            <p>
              <StaticImage
                className="service__image"
                alt="Сервис компании Dendor"
                src="../images/service.jpg"
                width={350}
                placeholder="#fff"
              />
              Высококвалифицированные специалисты нашего сервисного отдела
              осуществляют технические консультации по вопросам подбора,
              установки и эксплуатации продукции DENDOR.
            </p>
            <p>
              Весь гарантийный ремонт производится на территории сервисного
              центра. Благодаря современному оснащению сервисного центра,
              наличию склада запасных частей, ремонтные работы проводятся в
              кратчайшие сроки.
            </p>
            <p>
            Для вашего удобства был разработан сервис по самостоятельному восстановлению паспорта на изделие. Предлагаем ознакомиться с инструкцией по использованию данного сервиса:
            </p>
          </div>

          <div className="video mt-90  mb-30 text-center">
            <h2>ВОССТАНОВЛЕНИЕ ТЕХНИЧЕСКОГО ПАСПОРТА НА ИЗДЕЛИЕ </h2>
        {/*   <iframe width="100%" height="365" src="https://www.youtube.com/embed/N_1BnxKWCaI" title="Как восстановить технический паспорт на арматуру DENDOR за 2 минуты" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            */} <div className="mb-30 mt-60 btnTechPassport">
            <a target="_blanck" href="http://dendor.ddns.net/Passport/ru_RU/">Технический паспорт на арматуру DENDOR</a>
            </div>

          </div>

          <div className="video-content mt-90  mb-30 text-center">
            <h2 className="mb-30">
              Видеоинструкции по монтажу и наладке оборудования DENDOR:
            </h2>
            <div className="video-service">
              <div className="video_element">
                <iframe
                  src="https://www.youtube.com/embed/gTplD0D00Cc"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
              <div className="video_element">
                <iframe
                  src="https://www.youtube.com/embed/VBIk4jdO_iI"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
              <div className="video_element">
                <iframe
                  src="https://www.youtube.com/embed/hiv4l4fH4NU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
              <div className="video_element">
                <iframe
                  src="https://www.youtube.com/embed/R2D5aim4aO8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
              <div className="video_element">
                <iframe
                  src="https://www.youtube.com/embed/3R8L7Hp08QM"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="info mt-60 mb-30 ">
          <p>
            Если у вас возникли вопросы, наши профессионалы всегда будут рады на
            них ответить! Вы можете обратиться к ним напрямую или
            воспользоваться формой обратной связи
          </p>
        </div>
        <div className="service_contacts">
          <div className="service_content">
            <div className="service_contacts_img">
              <StaticImage
                placeholder="#fff"
                alt="Аватар"
                src="../images/avatar.png"
              />
            </div>
            <div id="service_phone" className="service_contacts_body">
              <div className="service_contacts_header">
                Начальник отдела сервиса
              </div>
              <div className="service_contcts_text">
                <ul>
                  <li className="name">Кузнецов Алексей Григорьевич</li>
                  <li>
                    тел.: +7 (812) 640-30-30, доб. 723, сот.: +7-965-060-28-07
                  </li>
                  <li>e-mail: kuznetsov@dendor.ru</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="service_content">
            <div className="service_contacts_img">
              <StaticImage
                placeholder="#fff"
                alt="Аватар"
                src="../images/avatar.png"
              />
            </div>
            <div className="service_contacts_body">
              <div className="service_contacts_header">
                Инженер сервисного отдела
              </div>
              <div className="service_contcts_text">
                <ul>
                  <li className="name">Зотов Герман Геннадьевич</li>
                  <li>
                    тел.: +7 (812) 640-30-30, доб. 739, сот.: +7-964-349-52-76
                  </li>
                  <li>e-mail: zotov@dendor.ru</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form />
    </Layout>
  );
};

export default Service;
